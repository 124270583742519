import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Img from "gatsby-image"

import "./workfinder.css"

const Workfinder = () => (
  <Layout>
    <SEO title="Artxcode client | Workfinder" />
    <div className="workfinder-header">
      <div className="workfinder-header-inner">
        <p className="workfinder-header-inner-subtitle">Client</p>
        <h1>Workfinder</h1>
      </div>
    </div>
    <div className="workfinder-content">
      <div className="workfinder-content-inner">
        <h2>The client</h2>
        <p>Workfinder helps make work experience easy and valuable for employers and students.
        They offer short remote work placements as a vital way for businesses to help develop the next generation of talent,
        whilst also delivering real commercial value. They inspire young people to find a future they'll love,
        to discover exciting career opportunities and explore the working world.
        </p>
        <h2>The work</h2>
        <p>
          Artxcode was contracted to help accelerate development of the Workfinder website prior to a launch.
          With students finishing university, the aim was to launch before the summer holidays,
          in order to give students time to apply to on-premises summer internships.
        </p>
        <p>
          However, due to COVID, and with employees across the country beginning to work from home,
          the business decided to include the ability to apply to remote internships, into an already tight deadline.
        </p>
        <p>
          Thankfully this was achieved and the product was launched in time for students to apply and gain placements.
        </p>
        <p>
          Artxcode was primarily involved in the core development of the Employer portal, where businesses could view and reply
          to students who had applied to them and manage the application process end to end. It also contributed to setting up frontend tooling,
          UTM analytics tracking, and general development across the platform and email system. All while maintaining 100% test coverage.
        </p>
        <div className="image-container">
          <Logo name="workfinder1" />
        </div>
        <p>
          Artxcode provided development across the technology stack. Some of the technologies used included JavaScript, React, CSS/SCSS,
          Python, Django, Django Rest Framework, Docker and AWS.
        </p>
        <p>
          Some of the companies students can apply to using the Workfinder platform include Zoopla, PayPal, MADE and Wayra, among many others.
        </p>
        <a
          href="https://workfinder.com"
          target="__blank"
          style={{
            color: `black`, textDecoration: `none`, borderBottom: `1px solid black`, paddingBottom: `0.625rem`,
          }}
        >Visit website</a>
      </div>
    </div>
  </Layout>
)

const Logo = ({ name }) => {
  const data = useStaticQuery(graphql`
    query {
      workfinder1: file(relativePath: { eq: "workfinder1.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      donation2: file(relativePath: { eq: "donation2.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  if (!data?.workfinder1?.childImageSharp?.fluid && !data?.donation2?.childImageSharp?.fluid) {
    return <div>Picture not found</div>
  }

  if (name === "workfinder1") {
    return <Img
      fluid={data.workfinder1.childImageSharp.fluid}
      imgStyle={{
        objectFit: 'contain',
      }}
    />
  }
  else if (name === "donation2") {
    return <Img
      fluid={data.donation2.childImageSharp.fluid}
      imgStyle={{
        objectFit: 'contain',
        marginLeft: '-12px',
      }}
    />
  }

  return null
}

export default Workfinder
